#departments_listing {
  .elements_listing {
    .element {
      a {
        &.text-heading:hover {
          color: $primary !important;
        }
      }
    }
  }
}