@include make-grid-columns-project();

.cursor-not-allowed {
  cursor: not-allowed;
}
.add_to_wishlist {
  .ci-heart-filled {
    display: none;
  }

  &.active {
    .ci-heart {
      display: none;
    }
    .ci-heart-filled {
      display: block;
    }
  }
}

.widget-categories {
  .accordion-header {
    &.no-childs {
      .accordion-button:after {
        display: none;
      }
    }
  }
}

.tags {
  .badge {
    margin-bottom: 4px;
    background-color: var(--badge-color);
    box-shadow: 0 0.5rem 1.125rem -0.275rem var(--badge-color);

    img {
      max-width: 30px;
      &.big {
        max-width: 85px;
      }
    }
  }
}

#ajax_loader {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);

  .load-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 66px;
    height: 20px;
    animation: c 1.8s linear infinite;
    -webkit-animation: c 1.8s linear infinite;

    &:before, &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      border-radius: 20px;
      background: hsla(0, 0%, 100%, 0.9);
    }
    &:before {
      left: 0;
      animation: d 1.8s linear infinite;
      -webkit-animation: d 1.8s linear infinite;
    }
    &:after {
      right: 0;
      animation: e 1.8s linear infinite;
      -webkit-animation: e 1.8s linear infinite;
      animation-delay: -0.9s;
      -webkit-animation-delay: -0.9s;
    }
  }
}

#searchCarousel {
  @include media-breakpoint-up(md) {
    .product-card {
      max-width: 300px;
    }
  }
}

.linepromos {
  .tns-controls {
    [data-controls=prev], [data-controls=next] {
      background-color: transparent;
      border: none;
    }
    [data-controls=prev] {
      left: -5px;
    }
    [data-controls=next] {
      right: -5px;
    }
  }
  .topbar-text {
    display: grid !important;
    align-items: center;
    min-height: 52px;
    padding: 10px 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #000;
    text-align: center;
    background-color: rgb(240, 216, 179);

    p {
      margin: 0px;
    }
  }
}

/* INQUIRY BUBBLE-FORM */
.btn-scroll-top.lifted {
  bottom: calc($spacer * 1.25 + 50px);

  @include media-breakpoint-down(lg) {
    body:not(#type_cart) & {
      bottom: calc($spacer * 4.25 + 50px);
    }
  }
  @include media-breakpoint-down(sm) {
    body:not(#type_cart) & {
      bottom: calc($spacer * 3.75 + 50px);
    }
  }
}
.inquiry-bubble-wrp {
  position: fixed;
  right: 1.25rem;
  bottom: 1.25rem;
  z-index: 1025;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background-color: #ff915F;
  border-radius: 22px;
  cursor: pointer;
  transition: all .3s;

  i {
    color: #ffffff;
    font-size: 25px;
  }
  .inquiry-bubble-tooltip {
    position: absolute;
    top: 50%;
    right: 100%;
    padding: 4px 10px;
    text-align: right;
    white-space: nowrap;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px #847e7e;
    transform: translateY(-50%);
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }
  &:hover {
    background-color: #ff7536;

    .inquiry-bubble-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  @include media-breakpoint-down(lg) {
    body:not(#type_cart) & {
      bottom: 4.25rem;
    }
  }
  @include media-breakpoint-down(sm) {
    right: $spacer;
    width: 35px;
    height: 35px;
  }
}