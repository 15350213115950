//
// Breadcrumb
// --------------------------------------------------

.breadcrumb-item {
  color: $breadcrumb-color;

  &::before {
    margin-top: .0625rem;
    font: {
      family: 'cartzilla-icons';
      size: .9em;
    }
    vertical-align: middle;
  }
  > a {
    transition: $nav-link-transition;
    color: $breadcrumb-color;

    &:hover {
      color: $breadcrumb-hover-color;
    }
    > i {
      margin: {
        top: -.175rem;
        right: .375rem;
      }
      font-size: 1.05em;
      vertical-align: middle;
    }
  }
  &.active { cursor: default; }
  &.act-expand-breadcrumb, &.act-expand-breadcrumb-desktop {
    display: none;
  }
  @include media-breakpoint-up(md) {
    &.act-expand-breadcrumb-desktop {
      display: block;
    }
    &.act-expand-breadcrumb-desktop ~ .breadcrumb-item:not(:last-child) {
      display: none;
    }
  }
  @include media-breakpoint-down(lg) {
    &.act-expand-breadcrumb {
      display: block;
    }
    &:not(:first-child):not(:last-child):not(.act-expand-breadcrumb) {
      display: none;
    }
  }
}


// Light version

.breadcrumb-light .breadcrumb-item  {
  color: $breadcrumb-light-color;

  &::before { color: $breadcrumb-light-divider-color; }
  > a {
    color: $breadcrumb-light-color;
    &:hover {
      color: $breadcrumb-light-hover-color;
    }
  }
  &.active { color: $breadcrumb-light-active-color; }
}
