@use "sass:map";

.navbar-expand-lg {
  .navbar-collapse {
    position: relative;

    .navbar-nav {
      .nav-item {
        &.fullwidth {
          position: static;
          > .dropdown-menu {
            left: 0;
            right: 0;
          }
          .mega-dropdown-column {
            @include media-breakpoint-up(lg) {
              width: 19rem;
              max-width: 19rem;
            }
          }
        }
      }
    }
  }
}