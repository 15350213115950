//
// Pagination
// --------------------------------------------------


// Paginations

.pagination {
  margin-bottom: 0;
  user-select: none;
}
.page-sizes {
  display: flex;
  align-items: center;
}
.size-link {
  position: relative;
  display: block;
  padding: 0.375rem 0.75rem;
  color: $pagination-color;
  text-decoration: if($link-decoration == none, null, none);
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;
  @include transition($pagination-transition);

  &:hover {
    z-index: 2;
    color: $pagination-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 3;
    color: $pagination-focus-color;
    background-color: $pagination-focus-bg;
    outline: $pagination-focus-outline;
    box-shadow: $pagination-focus-box-shadow;
  }
}
.page-size-item {
  &:not(:first-child) .size-link {
    margin-left: $pagination-margin-start;
  }
  &.active .size-link {
    z-index: 3;
    color: $pagination-active-color;
    @include gradient-bg($pagination-active-bg);
    border-color: $pagination-active-border-color;
  }

  &.disabled .size-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}

.page-link, .size-link {
  position: relative;
  transition: $pagination-transition;
  &:focus, &:hover {
    color: $pagination-color;
  }
  &.page-link-static:hover {
    border-color: transparent;
    background-color: $pagination-bg;
    color: $pagination-color;
  }
  > i {
    margin-top: -.125rem;
    vertical-align: middle;
  }
}
.page-item, .page-size-item {
  margin: .15rem;

  &.active {
    position: relative;
    z-index: 5;
    cursor: default;

    > .page-link, > .size-link {
      @include box-shadow($pagination-active-box-shadow);
    }
  }
}

// Sizing
.pagination .page-link, .page-sizes .size-link {
  @include border-radius($btn-border-radius);
  font-size: $pagination-font-size;
}
.pagination-sm .page-link {
  @include border-radius($btn-border-radius-sm);
  font-size: $pagination-font-size-sm;
}
.pagination-lg .page-link {
  @include border-radius($btn-border-radius-lg);
  font-size: $pagination-font-size-lg;
}


// Entry navigation

.entry-navigation {
  display: flex;
  border: $entry-navigation-border-width solid $entry-navigation-border-color;
  @include border-radius($entry-navigation-border-radius);
  background-color: $entry-navigation-bg;
}
.entry-navigation-link {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: $entry-navigation-padding-y $entry-navigation-padding-x;
  transition: $entry-navigation-transition;
  border-right: $entry-navigation-border-width solid $entry-navigation-border-color;
  color: $entry-navigation-color;
  font-weight: $entry-navigation-font-weight;
  text: {
    align: center;
    decoration: none;
  }
  &:last-child {
    border-right: 0;
  }
  > i {
    margin-top: -.1875rem;
    vertical-align: middle;
  }
  &:hover {
    color: $entry-navigation-hover-color;
    text-decoration: none;
  }
}
